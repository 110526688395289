import PageContainer from "../../container/PageContainer";
import React, {useEffect, useState} from "react";
import Input from "../../inputs/Input";
import {CgSearch} from "react-icons/cg";
import SvgArrowLeft02StrokeRounded from "../../Icons/ArrowLeft02StrokeRounded";
import {useNavigate} from "react-router-dom";
import Str from "../../../helpers/Str";
import Button from "../../button/Button";
import Form from "../../form/Form";
import Alert from "../../../helpers/Alert";
import Storage from "../../../helpers/Storage";
export type IFormLayout = {
    className?: string;
    children: React.ReactNode;
    contentClassName?: string;
    title?: string;
    onSubmit?: () => void;
    onDelete?: () => void;
    onNewData?: () => void;
    urlReturn: string;
    apiData?: any;
    state?: any;
    setState?: any;
    defaltValues?: any;
    sideMenu?: any;
    sideMenuHeight?: string;
    sideMenuWidth?: string;
    functionNewData?: any;
    showDeleteButton?: boolean;
    showNewDataButton?: boolean;
    permission?: string;
}
export default function FormLayout({
   children,
   title,
   urlReturn,
   className,
   onSubmit,
   onDelete,
   onNewData,
   apiData,
   state,
   setState,
   defaltValues,
   sideMenu,
   sideMenuHeight = 'h-[147px]',
   sideMenuWidth = 'md:w-[251px]',
   functionNewData,
   showDeleteButton = true,
   showNewDataButton = true,
   permission,
}: IFormLayout){
    const [loadBtn, setLoadBtn] = useState(false);
    const navigate = useNavigate();
    const permissions: any = Storage.getUserPermissions();
    const editValidation = permissions?.includes(permission);

    async function onSubmitForm(){

        setLoadBtn(true);

        if (onSubmit) {
            onSubmit();
            setLoadBtn(false);
            return;
        }

        if (apiData) {
            const {data, isError} = state.id ?
                await apiData.update(state.id, state)
                : await apiData.create(state);

            if (isError){
                if (typeof data === 'object') {
                    setLoadBtn(false);
                    Object.keys(data).forEach(function(key, index) {
                        Alert.error(data[key][0]);
                    });
                    return;
                }
                setLoadBtn(false);
                return Alert.error(data.message? data.message : data);
            }
            setLoadBtn(false);

            Alert.success(state.id ? 'Editado com sucesso' : 'Salvo com sucesso');

            setTimeout(function() {
                navigate(urlReturn)
            }, 3000);
        }
    }

    const newData = () => {
        if (functionNewData){
            functionNewData()
        }

        setState(defaltValues)
        navigate(urlReturn+'/formulario');
        /*if (defaltValues){
            setState(defaltValues)
        }else{
            setState({})
        }*/
    }



    const onDeleteForm = async () => {

        const confirm = await Alert.confirm(
            'Tem certeza que deseja excluir?',
            `Excluir esse dado?`
        );
        if (! confirm) return;
        setLoadBtn(true);
        if (onDelete) {
            onDelete();
            setLoadBtn(false);
            return;
        }
        if (apiData){
            const { data, isError } = await apiData.destroy(state.id);
            if (isError){
                setLoadBtn(false);
                if (typeof data === 'object') {
                    Object.keys(data).forEach(function(key, index) {
                        Alert.error(data[key][0]);
                    });
                    return;
                }
                setLoadBtn(false);
                return Alert.error(data.message? data.message : data);
            }
            setLoadBtn(false);
            Alert.success('Deletado com sucesso');

            setTimeout(function() {
                navigate(urlReturn)
            }, 5000);
        }
    }

    return(
        <PageContainer>
            <div className={'flex md:flex-row xs:flex-col gap-3 xs:flex-col-reverse'}>
                <div className={Str.tw(className,`md:h-[calc(95vh_-_90px)] overflow-y-auto scrollbar border  border-system-gray-gray80 rounded-xl md:mb-[90px] bg-system-blue-medium ${sideMenu ? 'md:w-[80%]' : 'w-full'}`)}>
                    <Form className="h-full"  onSubmit={onSubmitForm}>
                        <head className={'p-5 border-b flex gap-4 items-center border-system-gray-gray80'}>
                            <button type={'button'} onClick={() => navigate(urlReturn)}><SvgArrowLeft02StrokeRounded  width={20}/></button>
                            <div className={'text-xl font-bold'}>
                                {title}
                            </div>
                        </head>
                        <main className={''}>
                            {children}
                        </main>
                        {
                            editValidation ?
                                <footer className={'sticky top-[95vh] flex gap-4 p-5 border-t border-system-gray-gray80'}>
                                    <Button loading={loadBtn} type={'submit'} className={'w-[100px]'}>
                                        Salvar
                                    </Button>
                                    {
                                        showDeleteButton && state.id && (
                                            <Button loading={loadBtn} onClick={onDeleteForm} type={'button'} className={'w-[100px] bg-[#1C1C24] border text-border border-border'}>
                                                Excluir
                                            </Button>
                                        )
                                    }
                                    {
                                        showNewDataButton && (
                                            <Button onClick={newData} type={'button'} className={'w-[100px] bg-[#1C1C24] border text-border border-border'}>
                                                Novo
                                            </Button>
                                        )
                                    }
                                </footer>
                                :
                                ""
                        }

                    </Form>
                </div>
                {
                    sideMenu ?
                        <div className={`${sideMenuHeight} ${sideMenuWidth} border border-system-gray-gray80 rounded-xl bg-system-blue-medium`}>
                            <div className={'flex flex-col ml-5 gap-4 h-full justify-center'}>
                            {
                                sideMenu
                            }
                            </div>
                        </div>
                        :
                        ''
                }

            </div>

        </PageContainer>
    )
}