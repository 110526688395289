import PageContainer from "../../components/container/PageContainer";
import Input from "../../components/inputs/Input";
import PostComponent from "../../components/PostComponent";
import React, {useEffect, useState} from "react";
import Str from "../../helpers/Str";
import dashboardApi from "../../services/dashboardApi";
import dashboardMessageApi from "../../services/messageApi";
import {useNavigate} from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [cardSelected, setCardSelected] = useState('works_in_progress');
    const [statusProperties, setStatusProperties] = useState([
        {
            description: 'Obras em Andamento',
            data_consult: 'works_in_progress',
            quantity: 0,
        },
        {
            description: 'Obras Concluídas',
            data_consult: 'completed_works',
            quantity: 0,
        },
        {
            description: 'Casas MH',
            data_consult: 'casas_mh',
            quantity: 0,
        },
        {
            description: 'My Hunter Investimentos',
            data_consult: 'my_hunter',
            quantity: 0,
        }
    ]);
    const [menssageState, setMenssageState] = useState([
        {
            description: 'Abertas',
            data_consult: 'abertas',
            quantity: 0,
        },
        {
            description: 'Finalizadas',
            data_consult: 'finalizadas',
            quantity: 0,
        },
        {
            description: 'Em andamento',
            data_consult: 'andamento',
            quantity: 0,
        },
        {
            description: 'Aguardando',
            data_consult: 'aguardando',
            quantity: 0,
        }
    ]);
    async function getData() {
        const {data, isError} = await dashboardApi.getDataDashboardQuantity();

        if (!isError){
            const newStatusProperties = [...statusProperties]

            newStatusProperties.map((status: any, index: number) => {
                newStatusProperties[index]['quantity'] = data[status.data_consult] === undefined ? 0 : data[status.data_consult]
            })
            setStatusProperties(newStatusProperties);
        }
        setLoading(false)
    }

    async function getDataMessage() {
        const {data, isError} = await dashboardMessageApi.getDataDashboardQuantity();

        if (!isError){
            const newStatusMessages = [...menssageState]

            newStatusMessages.map((status: any, index: number) => {
                newStatusMessages[index]['quantity'] = data[status.data_consult] === undefined ? 0 : data[status.data_consult]
            })
            setMenssageState(newStatusMessages);
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
        getDataMessage()
    }, [])
    
    return(
        <PageContainer>
            <div className={'flex flex-col gap-5 mb-5 '}>
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex w-full justify-center gap-4'}>
                                {
                                    statusProperties.map((stPropertie) => {
                                        return(
                                            <div onClick={() => navigate('/dashboard/'+stPropertie.data_consult)} className={`flex bg-[#1C1C24] cursor-pointer flex-col items-center justify-center md:w-[250px] xs:w-full md:h-[100px] md:h-[162px]  hover:border-2 hover:border-border rounded-lg`}>
                                                <div className={'font-semibold text-system-primary md:text-[36px] xs:text-[20px]'}>
                                                    {stPropertie.quantity}
                                                </div>
                                                <div className={'font-semibold md:text-[14px] xs:text-[11px] text-center'}>
                                                    {stPropertie.description}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={'flex w-full justify-center gap-4'}>
                                {
                                    menssageState.map((menssage) => {
                                        return(
                                            <div onClick={() => navigate('/mensagens/'+menssage.data_consult)} className={`flex bg-[#1C1C24] cursor-pointer flex-col items-center justify-center md:w-[250px] xs:w-full md:h-[100px] md:h-[162px]  hover:border-2 hover:border-border rounded-lg`}>
                                                <div className={'font-semibold text-[#F94F4E] md:text-[36px] xs:text-[20px]'}>
                                                    {menssage.quantity}
                                                </div>
                                                <div className={'font-semibold xs:text-[11px] text-center'}>
                                                    {menssage.description}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>


                            <div className={'mb-[100px]'}>
                                <PostComponent className={'bg-system-blue-medium rounded-xl'}  />
                            </div>
                        </>
                }




            </div>
        </PageContainer>
    )
}