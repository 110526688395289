import {ITable} from "../../../types";

export default function TableHeader({columns}: ITable) {
    return(
        <thead className={'xs:text-[12px] md:text-[14px]'}>
            <tr >
                {
                    columns.map(({label, index, type}) => {
                        return (
                            <th key={index} className={'py-5 px-2'}>
                                {label}
                            </th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}