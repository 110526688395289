import PageContainer from "../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import workStatusApi from "../../services/workStatusApi";
import useTable from "../../hooks/useTable";
import Alert from "../../helpers/Alert";
import dashboardApi from "../../services/dashboardApi";
import Table from "../../components/Table";
import {PaginatedData} from "../../types";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

export default function Dashboard() {

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingTable, setloadingTable] = useState(false);
    const [cardSelected, setCardSelected] = useState(params.tab ? params.tab : 'works_in_progress');
    const [statusProperties, setStatusProperties] = useState([
        {
            description: 'Obras em Andamento',
            data_consult: 'works_in_progress',
            quantity: 0,
        },
        {
            description: 'Obras Concluídas',
            data_consult: 'completed_works',
            quantity: 0,
        },
        {
            description: 'Casas MH',
            data_consult: 'casas_mh',
            quantity: 0,
        },
        {
            description: 'My Hunter Investimentos',
            data_consult: 'my_hunter',
            quantity: 0,
        }
    ]);
    const [titleTable, setTitleTable] = useState('Obras em Andamento');

    const navigate = useNavigate();
    async function getData() {
        const {data, isError} = await dashboardApi.getDataDashboardQuantity();

        if (!isError){
            const newStatusProperties = [...statusProperties]

            newStatusProperties.map((status: any, index: number) => {
                newStatusProperties[index]['quantity'] = data[status.data_consult] === undefined ? 0 : data[status.data_consult]
            })
            setStatusProperties(newStatusProperties);
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])


    const { tableProps, loadData } = useTable({
        denyInitialLoad: true,
        dataLoader: async (params) => {
            const { data, isError } = await dashboardApi.getDataDashboard(cardSelected, params);
            if (isError) {
                Alert.error(data.message || data.error || 'Falha ao carregar dados.');
                return;
            }
            return data as any;
        }
    });

    useEffect(() => {
        loadData(cardSelected)
    }, [cardSelected])

    const onClickCard = async (dataConsult: string) => {
        const nameConsult = statusProperties.filter((st) => st.data_consult === dataConsult);
        setTitleTable(nameConsult[0]['description'])
        setCardSelected(dataConsult)
    }

    const columns: any = {
        works_in_progress: [
            { label: "Descrição", index: "description", type: 'string'},
            { label: "Status obra", index: "", type: 'progress'},
            { label: "Setor", index: "neighborhood", type: 'string'},
            { label: "Endereço", index: "address", type: 'address'},
            { label: "Tipo de imóvel", index: "type.description", type: 'string'},
        ],
        completed_works: [
            { label: "Descrição", index: "description", type: 'string'},
            { label: "Data Cadastro", index: "created_at", type: 'dateT'},
            { label: "Setor", index: "neighborhood", type: 'string'},
            { label: "Endereço", index: "address", type: 'address'},
            { label: "Tipo de imóvel", index: "type.description", type: 'string'},
        ],
        casas_mh: [
            { label: "Descrição", index: "description", type: 'string'},
            { label: "Data do cadastro", index: "created_at", type: 'dateT'},
            { label: "Setor", index: "neighborhood", type: 'string'},
            { label: "Endereço", index: "address", type: 'address'},
            { label: "Tipo de imóvel", index: "type.description", type: 'string'},
        ],
        my_hunter: [
            { label: "Descrição", index: "description", type: 'string'},
            { label: "Investidores", index: "clients_count", type: 'string'},
            { label: "Setor", index: "neighborhood", type: 'string'},
            { label: "Endereço", index: "address", type: 'address'},
            { label: "Tipo de imóvel", index: "type.description", type: 'string'},
        ],
    }

    const goToProperty = (dataForm: any) => {
        navigate('/propriedades/formulario/'+dataForm.id)
    }

    return(
        <PageContainer>
            <div className={'flex flex-wrap gap-5 w-full justify-center'}>
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <div className={'flex flex-col gap-3 w-full'}>
                            <div className={'flex gap-2 justify-center'}>
                                {
                                    statusProperties.map((stPropertie) => {
                                        return(
                                            <div onClick={() => onClickCard(stPropertie.data_consult)} className={`flex bg-[#1C1C24] cursor-pointer flex-col items-center justify-center md:w-[250px] xs:w-full md:h-[100px] md:h-[162px] ${stPropertie.data_consult === cardSelected ? 'border-2 border-border text-system-primary' : ''} hover:border-2 hover:border-border rounded-lg`}>
                                                <div className={'font-semibold text-system-primary md:text-[36px] xs:text-[20px]'}>
                                                    {stPropertie.quantity}
                                                </div>
                                                <div className={'font-semibold md:text-[14px] xs:text-[11px] text-center'}>
                                                    {stPropertie.description}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className={'border border-system-gray-gray80 rounded-xl mb-[90px] bg-system-blue-medium'}>
                                <div className={'p-5 text-system-primary md:text-[20px] xs:text-[15px] font-semibold border-b border-system-gray-gray80'}>
                                    {titleTable}
                                </div>
                                <div className="table_component flex" role="region">
                                {
                                    loadingTable ?
                                        <span className="flex items-center justify-center h-7">
                                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </span>
                                        :

                                        <Table
                                            onClick={goToProperty}
                                            {...tableProps}
                                            data={tableProps.data}
                                            columns={columns[cardSelected]}
                                        />


                                }

                            </div>
                            </div>
                        </div>

                }
            </div>

        </PageContainer>
    )
}