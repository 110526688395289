import PageContainer from "../../components/container/PageContainer";
import React, { useEffect, useState } from "react";
import AddButton from "../../components/button/AddButton";
import ButtonFolder from "../../components/button/ButtonFolder";
import folderApi from "../../services/foldersApi";
import {useNavigate} from "react-router-dom";

export default function FoldersFiles() {
    const [folders, setFolders] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getFolders = async () => {
        const {data, isError} = await folderApi.listAll();
            
        if (!isError) {
            if (data && Array.isArray(data)) {
                setFolders(data)
            } else {
                setFolders([]);
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getFolders()
    }, []);

    const onEdit = (folder_id: number | string) => {
        navigate('/pastas/formulario/'+folder_id)
    }

    return(
        <PageContainer>
            <div className={'border border-system-gray-gray80 rounded-xl  bg-system-blue-medium h-[calc(95vh_-_90px)] overflow-auto scrollbar'}>
                <div className={'p-5 md:flex xs:flex-row border-b items-center justify-between border-system-gray-gray80 text-xl font-bold'}>
                    Pastas de arquivos
                </div>
                <div className={'flex flex-wrap gap-3 w-full p-5'}>
                    {
                        folders.map((folder) => (
                            <ButtonFolder
                                key={folder.id}
                                description={folder.name}
                                onClick={() => navigate(`/pastas/${folder.id}/arquivos`)}
                                onClickEdit={() => onEdit(folder.id)}
                            />
                        ))
                    }
                </div>
            </div>
            <AddButton onClick={() => navigate('/pastas/formulario')}/>
        </PageContainer>
    )
}